<template>

    <div class="container-outer overwrite">
      <main id="full" class="mobile-fix">
        <div class="logo-error">
          <img src="~@/assets/img/logo-dt-large.svg" alt="Logo Direct Transport">
        </div>
        <section class="text-center error headings-reset">
          <div class="outer-height-full">
            <div class="content text-center">
              <h1>Error {{ errorCode }}</h1>
              <p v-if="errorCode === '401'">Je hebt onvoldoende rechten om deze pagina te bekijken.</p>
              <p v-if="errorCode === '404'">De opgevraagde pagina is niet gevonden.</p>
              <p v-if="!errorCode">Er is een onbekende fout opgetreden.</p>
              <a @click="goBack()" class="btn btn-primary">Ga terug</a>
            </div>
          </div>
        </section>
      </main>
    </div>
</template>

<script>

import store from '@/store';

export default {
  computed: {

    errorCode () {
      return window.location.hash && ['#401', '#404'].includes(window.location.hash) ? window.location.hash.substring(1) : '';
    }
  },
  methods: {
    goBack () {

      if (this.errorCode === '401') {

        switch (store.getters['userProfile']) {
          case 'admin':
            this.$router.push({ path: '/dashboard/admin-index' });
            break;
          case 'courier':
            this.$router.push({ path: '/dashboard/courier-index' });
            break;
          case 'customer':
            this.$router.push({ path: '/dashboard/customer-index' });
            break;
        }
      } else {
        this.$router.go(-1);
      }
    }
  }
};
</script>
